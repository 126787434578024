import Card from "../card/card";
import { useGetPromptsMutation } from "../../../api-integration/secure/prompts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fullPageLoader, updateAlertMessage, updateLibraryType } from "../../../api-integration/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Deploy from '../../../assets/icons/deploy.svg';
import TooltipComponent from "../bootstrap-component/tooltip-component";
import { getPageByURL, getPageSettingValue, getTranslatedText } from "../../../util/util";
import useManageUserPageSettings from "../../../hooks/useManageUserPageSettings";
import usePageTranslation from "../../../hooks/usePageTranslation";
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";
import PromptSummaryModal from "../../secure/modals/prompt-summary-modal";
import { use } from "i18next";

type SortCriteria = "newest_date" | "updated_date" | "alphabetical";

const PromptList = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PageTranslation = usePageTranslation();
  const location = useLocation();
  const pageName = getPageByURL(location.pathname);
  const { register } = useForm();
  const { user, LibraryTypeState, PageMemory, TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const [selectedLibraryType, setSelectedLibraryType] = useState<string>(localStorage.getItem('LibraryType') || user?.userPreferences?.userLibraryDefault || 'org');
  const [libSwitch, setLibSwitch] = useState({switchVal: localStorage.getItem('LibraryType') === "personal" ? 0 : 1});
  const [promptCount, setPromptCount] = useState(0);
  const [promptOriginalData, setPromptOriginalData] = useState([]);
  const [isAgentReadyPromptsChecked, setIsAgentReadyPromptsChecked] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<any>([]);
  const [reloadPromptSummaryModalAPI, setReloadPromptSummaryModalAPI] = useState<boolean>(false);
  
  const [getPromptsAPI, { data: getPromptsData, isLoading: isGetPromptsLoading, isSuccess: isGetPromptsSuccess, isError: isGetPromptsError, error: getPromptsError }] 
  = useGetPromptsMutation();
  
  const {manageUserPageSettingsAPI, manageUserPageSettingsData, isManageUserPageSettingsError, manageUserPageSettingsError, } 
  = useManageUserPageSettings();

  
  const [sortingData, setSortingData] = useState<sortingData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>("alphabetical");
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const [localIncludeRemovedPrompts, setLocalIncludeRemovedPrompts] = useState(0);
  const [isPromptWorkGroupSearchBarVisible, setIsShowPromptWorkGroupSearchBarVisible] = useState<boolean>(false);  // State for showing the search bar for 7 seconds
  const [promptWorkGroupSearchQuery, setPromptWorkGroupSearchQuery] = useState<string>('');   // State for controlling prompt search field
  const [isPromptWorkGroupActive, setIsPromptWorkGroupActive] = useState<boolean>(false);     // State to determine if the Prompt Work group is active or inactive
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<string>("");                     // State to store the selected Prompt Work group
  const [isFilteredWorkGroupPromptsExists, setIsFilteredWorkGroupPromptsExists] = useState<boolean>(false); // State to determine if there are any workgroup prompts available for selected workgroup.
  //const [userLibraryDefault, setUserLibraryDefault] = useState<string>('personal');
  
  // Effect to hide the input after 7 seconds
  useEffect(() => {
    let timer: any;
    if (isPromptWorkGroupSearchBarVisible) {
      timer = setTimeout(() => {
        setIsShowPromptWorkGroupSearchBarVisible(false);
      }, 7000);
    }
    return () => clearTimeout(timer);
  }, [isPromptWorkGroupSearchBarVisible]);

  // useEffect(() => {
  //   if (user) {
  //     setSelectedLibraryType(user?.userPreferences?.userLibraryDefault || "org");
  //   }
  // }, [user?.userPreferences?.userLibraryDefault]);

  useEffect(() => {
    setSelectedLibraryType(LibraryTypeState || user?.userPreferences?.userLibraryDefault || 'org');
  }, [LibraryTypeState, user?.userPreferences?.userLibraryDefault]);

  const handleToggle = (param: SortCriteria) => {
    setSortCriteria(param);
    handleSaveSettings('promptSort', param+"-"+!isToggled); //set this before changing the value of isToggled
    setIsToggled(!isToggled);
  };

  const handleSaveSettings = async (key:string, value:string) => {
    try {
      await manageUserPageSettingsAPI({ 
        "pageCode": pageName,
        "userPageSettings": [
          {
            "pageSettingKey": key,
            "pageSettingValue": value
          }
        ]
      });
    } catch (error) {
      dispatch(updateAlertMessage({ status: 'error', message: error }));
    }
  };

  useEffect(() => {
    if (isManageUserPageSettingsError || manageUserPageSettingsError) {
      dispatch(updateAlertMessage({ status: 'error', message: manageUserPageSettingsData.message }));
    }
  }, [isManageUserPageSettingsError, manageUserPageSettingsError]);

  useEffect(() => {
    //get promptSort from page settings
    const promptSortValue = getPageSettingValue(PageMemory, 'promptSort');
    if(promptSortValue){
      setSortCriteria(promptSortValue?.split('-')[0] as SortCriteria);
      setIsToggled(promptSortValue?.split('-')[1] == 'true' ? true : false);
    }
    //get libraryTypeFilter from page settings
    //const libraryTypeFilterValue = getPageSettingValue(PageMemory, 'libraryTypeFilter');
    // if(libraryTypeFilterValue === 'personal' ){
    //   handleLibraryTypeChange(0);
    // }
    // else if(libraryTypeFilterValue === 'org' || userLibraryDefault === 'org'){
    //   handleLibraryTypeChange(1);
    // }
    // if( userLibraryDefault === 'org'){
    //   handleLibraryTypeChange(1);
    // }
    // else{
    //   handleLibraryTypeChange(0);
    // }
    //get promptStatusFilter from page settings
    const promptStatusFilterValue = getPageSettingValue(PageMemory, 'promptStatusFilter');
    if(promptStatusFilterValue){
      setLocalIncludeRemovedPrompts(Number(promptStatusFilterValue));
    }
  }, [PageMemory]);

  //SORTING PROMPTS
  type sortingData = {
    CREATEDON: string;
    UPDATEDON: string;
    PROMPTNAME: string;
  };

  // useEffect(() => {
  //   setLibSwitch({ switchVal: LibraryType === 'org' ? 1 : 0});
  //   localStorage.setItem('LibraryType', LibraryType);
  //   dispatch(updateLibraryType(LibraryType));
  // }, [LibraryType]);
  

  useEffect(() => {
    if (isGetPromptsSuccess) {
      dispatch(fullPageLoader(false));
      if (getPromptsData?.success == true) {
        if (getPromptsData?.promptDetail) {          
          setPromptOriginalData(getPromptsData?.promptDetail);
          setPromptCount(getPromptsData.promptDetail.length);
        }
      }
      else if (getPromptsData?.success == false) {
        // dispatch(updateAlertMessage({ status: 'error', message: prompts?.message }));
        setPromptOriginalData([]);
      }
    }
    if (isGetPromptsError || getPromptsError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: getPromptsData?.message }));
    }
  }, [isGetPromptsSuccess, isGetPromptsError, getPromptsError]);

  useEffect(() => {
    dispatch(fullPageLoader(true));
    getPromptsAPI({
      libraryType: selectedLibraryType,
      includeRemovedPrompts: localIncludeRemovedPrompts, 
      "Page": getPageByURL(location.pathname) || 'home',
      "requestMode": props?.page === 'promptIo' ? "View" : "Manage"
    });
    if(props?.reloadPromptList){
      props?.setReloadPromptList(false);
    }
  }, [selectedLibraryType, localIncludeRemovedPrompts, location.pathname, props?.reloadPromptList, props?.isPromptSaved]);

  const handleChangeLibraryType = (e: any) => {
    const selectedValue = Number(e.target.value);
    //props.setIsLibraryTypeChanged(!props.isLibraryTypeChanged);
    setLibSwitch(prevVal => ({
      ...prevVal,
      switchVal: selectedValue
    }));

    if (selectedValue === 1) {
      setSelectedLibraryType('org');
      // Update State to set library type to 'org' bcoz CC deduction for prompts listed from org library will be from org account
      localStorage.setItem('LibraryType', 'org');
      dispatch(updateLibraryType('org'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'org');
      }
    } 
    else if (selectedValue === 0) {
      setSelectedLibraryType('personal');
      // Update State to set library type to 'personal' bcoz CC deduction for prompts listed in personal library will be from personal account
      localStorage.setItem('LibraryType', 'personal');
      dispatch(updateLibraryType('personal'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'personal');
      }
    }
    if(props?.page === 'promptStudio') {
      navigate('/app/prompts/create');
    }
  };

  // const handleLibraryTypeChange = (selectedValue:number) => {
  //   //it sets false due to when library changed by memory basis, appearence of execution tile does not effects
  //   props.setIsLibraryTypeChanged(false);
  //   setLibSwitch(prevVal => ({
  //     ...prevVal,
  //     switchVal: selectedValue
  //   }));

  //   if (selectedValue === 1) {
  //     // Update State to set library type to 'org' bcoz CC deduction for prompts listed from org library will be from org account
  //     localStorage.setItem('LibraryType', 'org');
  //     dispatch(updateLibraryType('org'));
  //     // Check if onPromptFieldChange is a function before calling it
  //     if (typeof props?.onPromptFieldChange === 'function') {
  //       props?.onPromptFieldChange('libraryType', 'org');
  //     }
  //   } 
  //   else if (selectedValue === 0) {
  //     // Update State to set library type to 'personal' bcoz CC deduction for prompts listed in personal library will be from personal account
  //     localStorage.setItem('LibraryType', 'personal');
  //     dispatch(updateLibraryType('personal'));
  //     // Check if onPromptFieldChange is a function before calling it
  //     if (typeof props?.onPromptFieldChange === 'function') {
  //       props?.onPromptFieldChange('libraryType', 'personal');
  //     }
  //   }
  // }

  const handleDisplayRemovePrompt = (e: any) => {
    const selectedValue = e.target.value;
    setLocalIncludeRemovedPrompts(Number(selectedValue));
  };

  useEffect(() => {
    if (isGetPromptsSuccess) {
      let filteredData = getPromptsData?.promptDetail || [];
      // If user has made workgroup active by clicking on W
      if(isPromptWorkGroupActive === true) {
        // If workgroup entered does not exist in the workgroup list (Invalid workgroup entered)
        if(selectedWorkGroup?.length === 0) {
          filteredData = [];
        }
        else {
          filteredData = filteredData.filter((item: any) => item?.PROMPTWORKGROUP?.toLowerCase() === selectedWorkGroup?.toLowerCase());
        }
        if(filteredData?.length === 0) {
          setSortingData([]);
          setIsFilteredWorkGroupPromptsExists(false);
          return;
        }
        else {
          setIsFilteredWorkGroupPromptsExists(true);
        }
      }
      // Filtering agent ready prompts if it is checked.
      if(isAgentReadyPromptsChecked === true) {
        filteredData = filteredData?.filter((item: any) => item.AGENTREADY === 1);
      }
      // Finally filtering on the basis of search query
      if (searchQuery !== '') {
        filteredData = filteredData.filter((item: any) =>
        item.PROMPTNAME && item?.PROMPTNAME?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
      }
      const sortedData = [...filteredData].sort((a, b) => {
        switch (sortCriteria) {
          case "newest_date":
            if (isToggled) {
              return (
                new Date(a.CREATEDON).getTime() - new Date(b.CREATEDON).getTime()
                );
            } else {
              return (
                new Date(b.CREATEDON).getTime() - new Date(a.CREATEDON).getTime()
              );
            }
          case "updated_date":
            if (isToggled) {
              return (
                new Date(a.UPDATEDON).getTime() - new Date(b.UPDATEDON).getTime()
              );
            } else {
              return (
                new Date(b.UPDATEDON).getTime() - new Date(a.UPDATEDON).getTime()
              );
            }
          case "alphabetical":
            const nameA = a?.PROMPTNAME || '';
            const nameB = b?.PROMPTNAME || '';
            return isToggled ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
          default:
            return 0;
        }
      });
      setSortingData(sortedData);
    }
  }, [sortCriteria, isToggled, promptOriginalData, searchQuery, isAgentReadyPromptsChecked, selectedWorkGroup, isPromptWorkGroupActive]);

  const handleSearchPromptWorkGroup = () => {
    // Hide the prompt workgroup search bar
    setIsShowPromptWorkGroupSearchBarVisible(false);
    // Get the prompt workgroup list
    const availableWorkGroups = getPromptsData?.promptDetail[0]?.availableWorkGroups;
    // To switch off the prompt workgroup feature, user needs to clear the prompt workgroup search field
    if(promptWorkGroupSearchQuery === '') {
      setIsPromptWorkGroupActive(false);
      setSelectedWorkGroup('');
      return;
    }
    // Checking if workgroup entered in the search bar exists in the prompt workgroup list
    const isWorkGroupPresent = availableWorkGroups?.find((item: any) => item.PROMPTWORKGROUP?.toLowerCase() === promptWorkGroupSearchQuery?.toLowerCase());
    setIsPromptWorkGroupActive(true);      
    // If workgroup exists
    if(isWorkGroupPresent?.PROMPTWORKGROUP?.length > 0) {
      setSelectedWorkGroup(isWorkGroupPresent.PROMPTWORKGROUP);
    }
    // Workgroup does not exist
    else {
      setSelectedWorkGroup('');
    }
  }

  return (
    <>
    <Card id={props?.cardId} 
      cardGlow={isGetPromptsSuccess && getPromptsData && (!getPromptsData.promptDetail || (getPromptsData.promptDetail && promptCount < 3)) ? true : false} 
      logo={true} cardHeightClass="h-100" 
      titleType={1} title="myPromptList.card.title" 
      help={true} Feedback={true} 
      helpTitle="myPromptList.cardHelp.title"
      helpContent="myPromptList.cardHelp.content"
      cardPadding="p-2"
    >
      {isLoggedIn && user?.accountType === 'corp' &&
      (  
      <div className="row g-0 mb-2">
        <div className="col-sm-6">
          <div className="mb-2 form-check form-check-inline">
            <TooltipComponent title={getTranslatedText("personal.tooltip", PageTranslation)}>
              <input className="form-check-input" 
                type="radio"
                id="personalRadio"
                {...register('libType')} 
                checked={libSwitch.switchVal === 0}
                value={0}
                onChange={handleChangeLibraryType}
              />
              <label className="form-check-label" htmlFor="personalRadio">
                <div dangerouslySetInnerHTML={{__html: getTranslatedText("p.text", PageTranslation)}} />
              </label>
            </TooltipComponent>
            {TranslationEditFlag && (
              <>
                <EditTranslationIcon translationKey="p.text" translationType='text'/>
                <EditTranslationIcon translationKey="personal.tooltip" translationType='tooltip' />
              </>
            )}
          </div>
          <div className="form-check form-check-inline">
            <TooltipComponent title={getTranslatedText("business.tooltip", PageTranslation)}>
              <input className="form-check-input" 
                type="radio" 
                {...register('libType')}  
                id="bussinessRadio"
                value={1} 
                checked={libSwitch.switchVal === 1}
                onChange={handleChangeLibraryType}
              />
              <label className="form-check-label" htmlFor="bussinessRadio">
                <div dangerouslySetInnerHTML={{__html: getTranslatedText("b.text", PageTranslation)}} />
              </label>
            </TooltipComponent>
            {TranslationEditFlag && (
              <>
                <EditTranslationIcon translationKey="b.text" translationType='text'/>
                <EditTranslationIcon translationKey="business.tooltip" translationType='tooltip' />
              </>
            )}
          </div>
            {/* <input 
              type="range" 
              className="form-range bc-range" 
              {...register('libType')} 
              min="0" max="1" step="1" 
              value={libSwitch.switchVal} 
              id="libType" 
              onChange={selectAccType} 
            />
            <div className="d-flex justify-content-between">
              <TooltipComponent title={getTranslatedText("personal.tooltip", PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText("personal.text", PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="personal.text" translationType='text'/>
                  <EditTranslationIcon translationKey="personal.tooltip" translationType='tooltip' />
                </>
              )}
              <TooltipComponent title={getTranslatedText("business.tooltip", PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText("business.text", PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="business.text" translationType='text'/>
                  <EditTranslationIcon translationKey="business.tooltip" translationType='tooltip' />
                </>
              )}
            </div> */}
          </div>
          <div className="col-sm-6">
            <div className="mb-2 form-check form-check-inline">
              <TooltipComponent title={getTranslatedText('activePrompts.tooltip', PageTranslation)}>
                <input className="form-check-input" 
                  type="radio" 
                  id="activePrompt"
                  value={0}
                  {...register('promptStatus')}
                  checked={localIncludeRemovedPrompts === 0}
                  onChange={handleDisplayRemovePrompt}
                />
                <label className="form-check-label" htmlFor="activePrompt">
                  <div dangerouslySetInnerHTML={{__html: getTranslatedText('activePrompt.text', PageTranslation)}} />
                </label>
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="activePrompt.text" translationType='text'/>
                  <EditTranslationIcon translationKey="activePrompts.tooltip" translationType='tooltip' />
                </>
              )}
            </div>
            <div className="form-check form-check-inline">
              <TooltipComponent title={getTranslatedText('removedPrompts.tooltip', PageTranslation)}>
                <input className="form-check-input" type="radio"
                  id="removedPrompt"
                  {...register('promptStatus')}
                  value={1}
                  checked={localIncludeRemovedPrompts === 1}
                  onChange={handleDisplayRemovePrompt}
                />
                <label className="form-check-label" htmlFor="removedPrompt">
                  <div dangerouslySetInnerHTML={{__html: getTranslatedText('removedPrompts.text', PageTranslation)}} />
                </label>
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="removedPrompts.text" translationType='text'/>
                  <EditTranslationIcon translationKey="removedPrompts.tooltip" translationType='tooltip' />
                </>
              )}
            </div>
            {/* <input className="form-range bc-range" 
              type="range"
              min="0" max="1" step="1"
              value={localIncludeRemovedPrompts}
              onChange={handleDisplayRemovePrompt}
            />
            <div className="d-flex justify-content-between">
              <TooltipComponent title={getTranslatedText('activePrompts.tooltip', PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText('activePrompt.text', PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="activePrompt.text" translationType='text'/>
                  <EditTranslationIcon translationKey="activePrompts.tooltip" translationType='tooltip' />
                </>
              )}
              <TooltipComponent title={getTranslatedText('removedPrompts.tooltip', PageTranslation)}>
                <small dangerouslySetInnerHTML={{__html: getTranslatedText('removedPrompts.text', PageTranslation)}} />
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="removedPrompts.text" translationType='text'/>
                  <EditTranslationIcon translationKey="removedPrompts.tooltip" translationType='tooltip' />
                </>
              )}
            </div> */}
          </div>
        </div>
      )}
      <div className="row mb-1">
        <div className="col-sm-8">
          {isGetPromptsSuccess  &&
            <h6 className="d-flex justify-content-between">
              <TooltipComponent title={getTranslatedText('sortByAlphabetical.tooltip', PageTranslation)}>
                <span className={`cursor-pointer ${sortingData?.length === 0 ? 'disabled-item' : ''}`} onClick={() => handleToggle("alphabetical")}>
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByAlphabetical.text', PageTranslation)}} />
                </span>
              </TooltipComponent>
              {TranslationEditFlag && (
                <div>
                  <EditTranslationIcon translationKey="sortByAlphabetical.text" translationType='text'/>
                  <EditTranslationIcon translationKey="sortByAlphabetical.tooltip" translationType='tooltip' />
                </div>
              )}
              &nbsp; | &nbsp;
              <TooltipComponent title={getTranslatedText('sortByNewest.tooltip', PageTranslation)}>
                <span className={`cursor-pointer ${sortingData?.length === 0 ? 'disabled-item' : ''}`} onClick={() => handleToggle("newest_date")}>
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByNewest.text', PageTranslation)}} />
                </span>
              </TooltipComponent>
              {TranslationEditFlag && (
                <div>
                  <EditTranslationIcon translationKey="sortByNewest.text" translationType='text'/>
                  <EditTranslationIcon translationKey="sortByNewest.tooltip" translationType='tooltip' />
                </div>
              )}
              &nbsp; | &nbsp;
              <TooltipComponent title={getTranslatedText('sortByUpdatedOn.tooltip', PageTranslation)}>
                <span className={`cursor-pointer ${sortingData?.length === 0 ? 'disabled-item' : ''}`} onClick={() => handleToggle("updated_date")}>
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByUpdatedOn.text', PageTranslation)}} />
                </span>
              </TooltipComponent>
              {TranslationEditFlag && (
                <div>
                  <EditTranslationIcon translationKey="sortByUpdatedOn.text" translationType='text'/>
                  <EditTranslationIcon translationKey="sortByUpdatedOn.tooltip" translationType='tooltip' />
                </div>
              )}
              &nbsp; | &nbsp;
              <TooltipComponent title={getTranslatedText('filterByWorkGroup.tooltip',PageTranslation)}>
                <span 
                className={`cursor-pointer ${isPromptWorkGroupActive ? 'fw-bold' : ''}`} 
                onClick={() => setIsShowPromptWorkGroupSearchBarVisible(true)} >
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('w.text', PageTranslation)}} />
                </span>
              </TooltipComponent>
              {TranslationEditFlag && (
                <div>
                  <EditTranslationIcon translationKey="w.text" translationType='text'/>
                  <EditTranslationIcon translationKey="filterByWorkGroup.tooltip" translationType='tooltip' />
                </div>
              )}
            </h6>
          }
        </div>
        <div className="col-sm-4">
            <div className="form-check">
              <TooltipComponent title={getTranslatedText('agentReadyPrompt.tooltip', PageTranslation)}>
                <input type="checkbox" id="aRPrompt" className="form-check-input" 
                  checked={isAgentReadyPromptsChecked} 
                  onChange={() => setIsAgentReadyPromptsChecked((prev) => !prev)}
                />
                <label className='form-check-label' htmlFor="aRPrompt">
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('agentReadyPrompt.text', PageTranslation)}} />
                </label>
              </TooltipComponent>
              {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="agentReadyPrompt.text" translationType='text'/>
                  <EditTranslationIcon translationKey="agentReadyPrompt.tooltip" translationType='tooltip' />
                </>
              )}
          </div>
        </div>
      </div>
      {isGetPromptsSuccess && promptOriginalData && promptOriginalData.length > 0 && (
        <div className="mb-1">
          {isPromptWorkGroupActive === false && (
            <div className="text-center">
              <span>{getTranslatedText('noWorkGroupSelected.message', PageTranslation)}</span>
              {TranslationEditFlag && <EditTranslationIcon translationKey="noWorkGroupSelected.message" translationType='text'/>}
            </div>
          )}
          {isPromptWorkGroupActive === true && selectedWorkGroup && (
            <div className="text-center">
              <span>{TranslationEditFlag && <EditTranslationIcon translationKey="workGroup.text" translationType='text'/>}{getTranslatedText('workGroup.text',PageTranslation)}: </span><span>{selectedWorkGroup}</span><span> {getTranslatedText('isActive.text',PageTranslation)} {TranslationEditFlag && <EditTranslationIcon translationKey="isActive.text" translationType='text'/>}</span>
            </div>
          )}
          {isPromptWorkGroupSearchBarVisible && (
            <>
              <input
                className="form-control mb-2"
                type="text"
                placeholder={getTranslatedText('searchByWorkGroup.placeholder', PageTranslation)}
                value={promptWorkGroupSearchQuery}
                onChange={(e) => setPromptWorkGroupSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchPromptWorkGroup();
                  }
                }}
                onBlur={handleSearchPromptWorkGroup}
              />
              {TranslationEditFlag && <EditTranslationIcon translationKey='searchByWorkGroup.placeholder' translationType='placeholder'/>}
            </>
          )}
          <input
            className='form-control'
            type="text"
            placeholder={getTranslatedText('promptSearch.placeholder', PageTranslation)}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {TranslationEditFlag && <EditTranslationIcon translationKey='promptSearch.placeholder' translationType='placeholder'/>}
        </div>
      )
      }
      {isPromptWorkGroupActive === true && isFilteredWorkGroupPromptsExists === false && (
        <div className="text-danger text-center">
          <span dangerouslySetInnerHTML={{__html: getTranslatedText('noPromptForSelectedWorkGroup.message', PageTranslation)}}/>
          {TranslationEditFlag && <EditTranslationIcon translationKey='noPromptForSelectedWorkGroup.message' />}
        </div>
      )}
      <div className="overflow-auto scrollbar h-35vh">
        {isGetPromptsLoading 
        ? (
          <div className='text-center'>
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                  <span className="visually-hidden">Loading…</span>
              </div>
          </div>
        ) 
        : isGetPromptsSuccess && sortingData && sortingData.length > 0  ?
        (sortingData.map((prompt: any, index: number) => (
            <h6 
              className="cursor-pointer mb-3" 
              key={index} 
            >
              <div className="row g-0">
                <div className="col-auto">
                  <TooltipComponent title={getTranslatedText('promptSummary.tooltip',PageTranslation)}>
                    <img 
                      src={Deploy} 
                      alt= {getTranslatedText('imageAlt.text', PageTranslation)} 
                      className='pe-1 h-1 cursor-pointer'
                      data-bs-toggle='modal'
                      data-bs-target='#promptSummaryModalId'
                      onClick={() => {
                        setSelectedPrompt(prompt);
                        setReloadPromptSummaryModalAPI(true);
                      }}
                    />
                  </TooltipComponent> 
                </div>
                <div className="col">
                  <TooltipComponent additionalClass ='bc-text-limit-3-tooltip' title={prompt?.PROMPTDESCRIPTION ? prompt?.PROMPTDESCRIPTION : getTranslatedText('promptDescriptionNotAvailable.message', PageTranslation)}>  
                    <span onClick={() => {
                      props.onLibraryTypeChange();
                      if (props?.actionURL) {
                        navigate(`${props?.actionURL}/${prompt?.GPTBLUEPROMPTID}`);
                      } else {
                        navigate(`/app/assetIo/${prompt.URLCODE}&assetType=prompt`);
                      }
                    }}
                  >
                    {prompt?.PROMPTNAME ? prompt?.PROMPTNAME : '-'}
                  </span>
                  </TooltipComponent>
                </div>
              </div>
            </h6>
          ))
        ) 
        : isGetPromptsSuccess && promptOriginalData && promptOriginalData.length === 0 ?
        (
          <>
          <p dangerouslySetInnerHTML={{ __html: selectedLibraryType === 'personal' ? getTranslatedText('personalLibraryEmpty.message', PageTranslation) : getTranslatedText('orgLibraryEmpty.message', PageTranslation) }}></p>
          {TranslationEditFlag && (
            <EditTranslationIcon translationKey={selectedLibraryType === 'personal' ? "personalLibraryEmpty.message" : "orgLibraryEmpty.message"} translationType='text'/>
          )}
          </>
        )
        :  ((isPromptWorkGroupActive === true && isFilteredWorkGroupPromptsExists === true ) || isPromptWorkGroupActive === false) && (
          <>
            <p className="text-center">{getTranslatedText('noRecordsFound.message', PageTranslation)}</p>
            {TranslationEditFlag && <EditTranslationIcon translationKey='noRecordsFound.message' translationType='text'/>}
          </>
        )
        }
      </div>
    </Card>
    <PromptSummaryModal 
      id='promptSummaryModalId'
      selectedPromptData={selectedPrompt}
      reloadAPIs={reloadPromptSummaryModalAPI}
      setReloadAPIs={setReloadPromptSummaryModalAPI}
    />
    </>
  )
};
export default PromptList;
