import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import ApiMiddleWare from '../api-middleware';

// Define a service using a base URL and expected endpoints
export const secureStore = createApi({
  reducerPath: 'secureStore',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (header) => {
      header.set("Accept", 'application/json');
      return header
    },
  }),
  endpoints: (builder) => ({
    Logout: builder.mutation({
      query: (body) => {
        return {
          url: 'gptblueSecurity/logOutUser/',
          method: 'POST',
          body: {
          }
        }
      }
    }),
    getUserProfile: builder.mutation({
      query: (body) => {
        return {
          url: 'userManagerCFC/getUserProfile/',
          method: 'POST',
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getSubPlan: builder.mutation({
      query: (body) => {
        return {
          url: 'gptBlueSystemCFC/getGptBlueSubPlan/',
          method: 'POST',
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    postUserProfile: builder.mutation({
      query: (userPostData) => {
        return {
          url: 'userManagerCFC/updateUserProfile/',
          method: 'POST',
          body: {...userPostData}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getGptBlueAccTransHistory: builder.mutation({
      query: (body)=>{
        return {
          url: 'gptBlueSystemReportCFC/getGptBlueAccTransHistory/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getGptBlueAccTransByObject: builder.mutation({
      query: (body)=>{
        return { 
          url: 'gptBlueSystemReportCFC/getGptBlueAccTransByObject/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCountryList: builder.mutation({
      query: () => {
        return {
          url: 'gptBlueSystemCFC/getCountry/',
          method: 'POST',
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getOrgnizationsList: builder.mutation({
      query: (body) => {
        return {
          url: '/gptBlueSCCCFC/getOrganizations/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getOrganizationRoles: builder.mutation({
      query: (body) => {
        return {
          url: '/gptBlueSCCCFC/getAllSCCRoles/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getOrgnizationsLevel: builder.mutation({
      query: (body) => {
        return {
          url: 'gptBlueSCCCFC/getOrgLevel/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getUserFeedback: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueUserFeedback/getUserFeedback/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addUpdateOrg: builder.mutation({
      query: (body) => {
        return {
          url: '/gptBlueSCCCFC/addUpdateOrg/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCompanyDetail: builder.mutation({
      query: (body) => {
        return {
          url: 'gptBlueSCCCFC/getCompanyDetail/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    connectAccount: builder.mutation({
      query: (body) => {
        return {
          url: 'userManagerCFC/ConnectAccount/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    generateImage: builder.mutation({
      query: (body) => {
        return {
          url: 'gptBlueSystemCFC/generateImage/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    generateReferralId: builder.mutation({
      query: (body) => {
        return {
          url: 'userManagerCFC/generateRefKey/',
          method: 'POST',
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    uploadImage: builder.mutation({
      query: (payload) => {
        return {
          url: 'userManagerCFC/uploadImage/',
          method: 'POST',
          body: {...payload}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    unDeployPrompt: builder.mutation({
      query: (body) => {
        return {
          url: '/gptbluePrompt/undeployGptBluePrompt/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    uploadUsers: builder.mutation({
      query: (formData) => {
        return {
          url: 'gptBlueTeamManager/uploadUsers/',
          method: 'POST',
          body: {...formData}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: 'gptBlueAccessCFC/changePassword/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    checkCCBalance: builder.mutation({
      query: (body) => {
        return {
          url: 'gptCommerce/checkUserCCBalance/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    gptBlueSocialShare: builder.mutation({
      query: (body) => {
        return {
          url: 'gptbluePrompt/gptBlueSocialShare/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPromptsApproval: builder.mutation({
      query: (body) => {
        return {
          url: 'gptblueManagePrompt/getPromptsForReview/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),

    LibApprovalAction:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/libraryRequestReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),

    RequestLibraryApproval: builder.mutation({
      query: (body) => {
        return {
          url: 'gptBlueAdmin/getLibraryForApproval/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    processPromptsForReview: builder.mutation({
      query: (body) => {
        return {
          url: 'gptblueManagePrompt/processPromptsForReview/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCustomModel: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueCustomModel/getGPTCustomModel/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addModel: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueCustomModel/addUpdateCustomModel/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    updateFiles: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueCustomModel/deleteCustomModelFiles/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    askChatGPT:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptbluePublicCFC/askGPTBlueSimpleMode/ ",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getChatHistory: builder.mutation({
      query: (body) => {
        return {
          url: "/gptbluePrompt/getSimpleChatHistory/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addUser: builder.mutation({
      query: (body)=>{
        return {
          url: 'userManagerCFC/addUser/',
          method: 'POST',
          body:{...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getTeam: builder.mutation({
      query: (body)=>{
        return {
          url: '/gptBlueTeamManager/getTeam/',
          method: 'POST',
          body:{...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    verifySecurityCodes: builder.mutation({
      query: (body)=>{
        return {
          url: '/userManagerCFC/verifySecurityCodes/',
          method: 'POST',
          body:{...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    resendCode: builder.mutation({
      query: () => {
        return {
          url: '/userManagerCFC/resendCode/',
          method: 'POST',
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    diassociateAccount: builder.mutation({
      query: () => {
        return {
          url: '/userManagerCFC/DisassociateAccount/',
          method: 'POST',
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    companyCreateRequest: builder.mutation({
      query: (body) => {
        return {
          url: '/gptBlueSCCCFC/CompanyCreateRequest',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getAccCreateRequest: builder.mutation({
      query: (body) => {
        return {
          url: '/gptBlueSCCCFC/getAccCreateRequest/',
          method: 'POST',
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    requestApprovalAction:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/accCreateRequestReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    requestApproval:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/getAccCreateRequest/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    checkBLCAdminSecurity:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/checkBLCAdminSecurity/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addFeedback:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueUserFeedback/addUpdUserFeedback/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getOrgStructure:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueSCCCFC/getOrgStructureJSON/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getLibraryToManage:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptblueLibrary/getLibraryToManage/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPartners:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBluePartner/getPartners/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),

    approvePartners:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBluePartner/partnerRequestReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),

    addUpdateLibrary: builder.mutation({
      query: (body) => {
        return {
          url: "/gptblueLibrary/addUpdateLibrary/",
          method: "POST",
          body : {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getUserConnection:  builder.mutation({
      query: (body) => {
        return {
          url: "/userManagerCFC/getUserConnection/",
          method:"POST",
          body: {...body}

        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getMessagesList:  builder.mutation({
      query: (body) => {
        return {
          url: "/gptUserMessage/getMyMessageSummary/",
          method:"POST",
          body: {...body}

        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    sendUserMessage: builder.mutation({
      query: (body) => {
        return {
          url: "/gptUserMessage/SendMessage",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getMessageDetails:  builder.mutation({
      query: (body) => {
        return {
          url: "/gptUserMessage/getMyMessageDetail",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    updateMessage: builder.mutation({
      query: (body) => {
        return {
          url: "/gptUserMessage/UpdateMessage/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    searchUser: builder.mutation({
      query:(body) => {
        return {
          url: "/userManagerCFC/searchUser/",
          method:"POST",
          body:{...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPromptResultHistory:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptbluePrompt/getGPTBluePromptResultHistory/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getLibrary:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptblueLibrary/getLibrary/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    validateAuthToken:  builder.mutation({
      query: () => {
        return {
         url: "/gptblueSecurity/validateAuthToken/",
          method:"POST",
          body: {}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    updateUserSettings:  builder.mutation({
      query: (body) => {
        return {
         url: "userManagerCFC/updateUserSettings/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getSegment:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueSystemCFC/getSegment/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    postSegment:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/manageSegment/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getDeactivateModel:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueCustomModel/deactivateCustomModel/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    submitSurveyResponse:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueSurvey/submitSurveyResponse/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getRolesAndRights:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/getRolesAndRights/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageRoleRights:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/manageRoleRights/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    removePromptFromLib:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptbluePrompt/removePromptFromLib",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    createPromptVersionHistory:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptbluePrompt/createPromptVersionHistory/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getDeveloper:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueUserFeedback/getDeveloper/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getReleases:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueUserFeedback/getReleases/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getBadges: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/getBadges/ ",
         method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getMailserver:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueUserFunctions/getMailserver/",

          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),

    updateBadges: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/manageBadges/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getUserBadges: builder.mutation({
      query: (body) => {
        return {
         url: "/userManagerCFC/getUserBadges/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    assignUserBadges:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAdmin/gptBlueManageUserBadge",
         method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageMailServer:  builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueUserFunctions/manageMailServer/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    updateMyBadge:  builder.mutation({
      query: (body) => {
        return {
         url: "/userManagerCFC/updateMyBadge/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getAgents: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAgent/getGPTAgent/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addUpdateAgents: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueAgent/addUpdateAgent",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    submitLibAuthorRequest: builder.mutation({
      query: (body) => {
        return {
         url: "/gptblueLibrary/submitLibAuthorRequest/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getAuthorsForReview: builder.mutation({
      query: (body) => {
        return {
         url: "/gptblueManageAuthor/getAuthorsForReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    authorReview: builder.mutation({
      query: (body) => {
        return {
         url: "/gptblueManageAuthor/authorReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getUserActionHistory: builder.mutation({
      query: (body) => {
        return {
         url: "/userManagerCFC/getUserActionHistory/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getSearchPrompts: builder.mutation({
      query: (body) => {
        return {
         url: "/gptbluePublicCFC/getGPTPromptAgentSearch",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getTranslations: builder.mutation({
      query: (body) => {
        return {
         url: "/gptBlueSystemCFC/getTranslation/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageTranslations: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAdmin/manageTranslation/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    updateCompanyDetails: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSCCCFC/updateCompanyDetail/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    verifyVanityCode: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSCCCFC/isAccVanityCodeAvailable/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),

    getVendors: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBluePartner/getVendors",
           method:"POST",
            body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPartnerProgram: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBluePartner/getPartnerProgram/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    enablePartnerProgram: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBluePartner/enablePartnerProgram/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addUpdatePartner: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBluePartner/AddEditPartner/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    checkUserAccess: builder.mutation({
      query: (body) => {
        return {
          url: "/userManagerCFC/checkUserAccess/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageUserPageSettings: builder.mutation({
      query: (body) => {
        return {
          url: "/userManagerCFC/manageUserPageSettings/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPartnerCategory: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBluePartner/getPartnerCategory/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPromptFramingTags: builder.mutation({
      query: (body) => {
        return {
          url: "/gptbluePrompt/getPromptFramingTags/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }), 
    getBusinessModel: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getBusinessModel",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getOffering: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getOffering/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getTargetAudience: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getTargetAudience/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCompanySize: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getCompanySize/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCompanyAge: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getCompanyAge/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCompanyRevenue: builder.mutation({
      query: (body) => {
        return {
          url: "gptBlueSystemCFC/getCompanyRevenue/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getSolutions: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSolution/getSolutions/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPayoutRequest: builder.mutation({
      query: (body) => {
        return {
          url: "/gptCommerce/getPayoutRequest/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addUpdateSolutions: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSolution/addUpdateSolution/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPayoutRequestToProcess: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAdmin/getPayoutRequestToProcess/",

          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    processPayoutRequest: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAdmin/processPayoutRequest/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    savePayoutRequest: builder.mutation({
      query: (body) => {
        return {
          url: "/gptCommerce/savePayoutRequest/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getCCBalanceForPayout: builder.mutation({
      query: (body) => {
        return {
          url: "/gptCommerce/getCCBalanceForPayout/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getDNACharSet: builder.mutation({
      query: (body) => {
        return {
          url: "gptBlueSystemCFC/getDNACharSet/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getLaunchPads: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLaunchpad/getLaunchpads/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    updateLaunchpad: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLaunchpad/addUpdateLaunchpad/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    setObjectDNA: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/setObjectDNA/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    deployAgent: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgent/deployAgent/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    undeployAgent: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgent/undeployAgent/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getAgentsForReview: builder.mutation({
      query: (body) => {
        return {
          url: "gptblueManageAgent/getAgentsForReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    agentReview: builder.mutation({
      query: (body) => {
        return {
          url: "/gptblueManageAgent/agentReview/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getAgentToExecute: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgentExecute/getAgentToExecute/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getDBConnectionDetails: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueUserFunctions/getDBConnectionDetails/",
           method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    executeAgent: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgentExecute/executeAgent/",

          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageAgentExecution: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgentExecute/manageAgentExecution/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageDBConnection: builder.mutation({
      query: (body) => {
        return {
          url: "gptBlueUserFunctions/manageDBConnection/",
          method:"POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getDBQueryFunctions: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueUserFunctions/getDBQueryFunctions/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageDBQueryFunctions: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueUserFunctions/manageDBQueryFunctions/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getDBType: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getDBType/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }), 
    updateExecutedPromptInstance: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgentExecute/updateExecutedPromptInstance/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    testDBQueryFunctions: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueUserFunctions/testDBQueryFunctions/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getLLMModels: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getLLMModels/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    deploySolution: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSolution/deploySolution/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    undeploySolution: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSolution/undeploySolution/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getSolutionsForReview: builder.mutation({
      query: (body) => {
        return {
          url: "/gptblueManageSolution/getSolutionsForReview/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    solutionReview: builder.mutation({
      query: (body) => {
        return {
          url: "/gptblueManageSolution/solutionReview/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    executeLaunchpad: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLaunchpadExecute/executeLaunchpad/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addLaunchpadTeam: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLaunchpad/addLaunchpadTeam/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    shareAgent: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgent/shareAgent/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    shareSolution: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSolution/shareSolution/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getLaunchpadToExecute: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLaunchpadExecute/getLaunchpadToExecute/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    manageLaunchpadExecution: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLaunchpadExecute/manageLaunchpadExecution/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    downloadAsset: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueCommon/downloadAsset/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    removeAssetFromLibrary: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueCommon/removeAssetFromLib/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    userLinkedinValidation: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLinkedIn/validateLinkedInAuthSession/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    userTwitterValidation: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueTwitter/validateTwitterAuthSession/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    shareLinkedinPost: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueLinkedIn/shareLinkedInPost/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    shareTwitterPost: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueTwitter/postTweets/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getUpgradeQuestions: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getUpgradeQuestions/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    saveUpgradeRequest: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueUpgrade/saveUpgradeRequest/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getPromptTypes: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemCFC/getPromptTypes/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getInternalAccTransSummary: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSystemReportCFC/getGptBlueAccTransSummary/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getWorkGroupPrompts: builder.mutation({
      query: (body) => {
        return {
          url: "/gptbluePrompt/getWorkGroupPrompts/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    copyAgent: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueAgent/copyAgent/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    copySolution: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueSolution/copySolution/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    getWorkspace: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueWorkspace/getWorkspace",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addEditWorkspace: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueWorkspace/AddEditWorkspace/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
    addWorkspaceTeam: builder.mutation({
      query: (body) => {
        return {
          url: "/gptBlueWorkspace/addWorkspaceTeam/",
          method: "POST",
          body: {...body}
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => { try { ApiMiddleWare(await queryFulfilled, dispatch) } catch (error: any) { } }
    }),
  })
});

export const {
  useLogoutMutation,
  useGetUserProfileMutation,
  usePostUserProfileMutation,
  useGetSubPlanMutation,
  useGetGptBlueAccTransHistoryMutation,
  useGetGptBlueAccTransByObjectMutation,
  useGetCountryListMutation,
  useGetOrgnizationsListMutation,
  useGetOrgnizationsLevelMutation,
  useAddUpdateOrgMutation,
  useGetCompanyDetailMutation,
  useConnectAccountMutation,
  useGenerateImageMutation,
  useGenerateReferralIdMutation,
  useUploadImageMutation,
  useUploadUsersMutation,
  useChangePasswordMutation,
  useCheckCCBalanceMutation,
  useGptBlueSocialShareMutation,
  useGetPromptsApprovalMutation,
  useProcessPromptsForReviewMutation,
  useAddUserMutation,
  useGetTeamMutation,
  useGetCustomModelMutation,
  useAskChatGPTMutation,
  useGetChatHistoryMutation,
  useVerifySecurityCodesMutation,
  useResendCodeMutation,
  useDiassociateAccountMutation,
  useCompanyCreateRequestMutation,
  useGetAccCreateRequestMutation,
  useRequestApprovalActionMutation,
  useRequestApprovalMutation,
  useGetUserFeedbackMutation,
  useCheckBLCAdminSecurityMutation,
  useAddFeedbackMutation,
  useGetOrgStructureMutation,
  useAddModelMutation,
  useGetOrganizationRolesMutation,
  useGetLibraryToManageMutation,
  useUnDeployPromptMutation,
  useAddUpdateLibraryMutation,
  useGetUserConnectionMutation,
  useGetPromptResultHistoryMutation,
  useGetLibraryMutation,
  useValidateAuthTokenMutation,
  useUpdateFilesMutation,
  useUpdateUserSettingsMutation,
  useSearchUserMutation,
  useGetMessagesListMutation,
  useSendUserMessageMutation,
  useGetMessageDetailsMutation,
  useUpdateMessageMutation,
  useGetSegmentMutation,
  usePostSegmentMutation,
  useSubmitSurveyResponseMutation,
  useGetDeactivateModelMutation,
  useGetRolesAndRightsMutation,
  useManageRoleRightsMutation,
  useRemovePromptFromLibMutation,
  useCreatePromptVersionHistoryMutation,
  useGetDeveloperMutation,
  useGetReleasesMutation,
  useGetBadgesMutation,
  useUpdateBadgesMutation,
  useGetUserBadgesMutation,
  useAssignUserBadgesMutation,
  useGetMailserverMutation,
  useManageMailServerMutation,
  useUpdateMyBadgeMutation,
  useGetAgentsMutation,
  useAddUpdateAgentsMutation,
  useSubmitLibAuthorRequestMutation,
  useGetAuthorsForReviewMutation,
  useAuthorReviewMutation,
  useGetUserActionHistoryMutation,
  useGetSearchPromptsMutation,
  useRequestLibraryApprovalMutation,
  useLibApprovalActionMutation,
  useGetTranslationsMutation,
  useManageTranslationsMutation,
  useUpdateCompanyDetailsMutation,
  useVerifyVanityCodeMutation,
  useGetPartnersMutation,
  useApprovePartnersMutation,
  useGetVendorsMutation,
  useGetPartnerProgramMutation,
  useEnablePartnerProgramMutation,
  useAddUpdatePartnerMutation,
  useCheckUserAccessMutation,
  useManageUserPageSettingsMutation,
  useGetPartnerCategoryMutation,
  useGetPromptFramingTagsMutation,
  useGetBusinessModelMutation,
  useGetOfferingMutation,
  useGetTargetAudienceMutation,
  useGetCompanySizeMutation,
  useGetCompanyAgeMutation,
  useGetCompanyRevenueMutation,
  useGetSolutionsMutation,
  useAddUpdateSolutionsMutation,
  useGetPayoutRequestMutation,
  useGetPayoutRequestToProcessMutation,
  useProcessPayoutRequestMutation,
  useSavePayoutRequestMutation,
  useGetCCBalanceForPayoutMutation,
  useGetDNACharSetMutation,
  useGetLaunchPadsMutation,
  useUpdateLaunchpadMutation,
  useSetObjectDNAMutation,
  useDeployAgentMutation,
  useUndeployAgentMutation,
  useGetAgentsForReviewMutation,
  useAgentReviewMutation,
  useGetAgentToExecuteMutation,
  useGetDBConnectionDetailsMutation,
  useManageDBConnectionMutation,
  useGetDBQueryFunctionsMutation,
  useManageDBQueryFunctionsMutation,
  useGetDBTypeMutation,
  useExecuteAgentMutation,
  useManageAgentExecutionMutation,
  useUpdateExecutedPromptInstanceMutation,
  useTestDBQueryFunctionsMutation,
  useGetLLMModelsMutation,
  useDeploySolutionMutation,
  useUndeploySolutionMutation,
  useGetSolutionsForReviewMutation,
  useSolutionReviewMutation,
  useExecuteLaunchpadMutation,
  useAddLaunchpadTeamMutation,
  useShareAgentMutation,
  useShareSolutionMutation,
  useGetLaunchpadToExecuteMutation,
  useManageLaunchpadExecutionMutation,
  useDownloadAssetMutation,
  useRemoveAssetFromLibraryMutation,
  useUserLinkedinValidationMutation,
  useUserTwitterValidationMutation,
  useShareLinkedinPostMutation,
  useShareTwitterPostMutation,
  useGetUpgradeQuestionsMutation,
  useSaveUpgradeRequestMutation,
  useGetPromptTypesMutation,
  useGetInternalAccTransSummaryMutation,
  useGetWorkGroupPromptsMutation,
  useCopyAgentMutation,
  useCopySolutionMutation,
  useGetWorkspaceMutation,
  useAddEditWorkspaceMutation,
  useAddWorkspaceTeamMutation,
} = secureStore;
